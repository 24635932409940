import * as React from 'react'
import * as css from './Resources.module.css'
import SanityBlock from 'components/common/SanityBlock'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import { MdKeyboardArrowRight } from 'react-icons/md'

const Resource = ({ title, description, cta, image }) => (
  <article className={css.resource}>
    <Link className={css.link} to={cta.url}>
      <GatsbyImage
        className={css.img}
        image={getImage(image.asset)}
        alt={title}
        draggable="false"
      />
    </Link>

    <div className={`${css.inner} richtext`}>
      <h3 className="h2">{title}</h3>
      <p>{description}</p>
      <div>
        <Link className="link with-icon" to={cta.url}>
          {cta.label}
          <MdKeyboardArrowRight />
        </Link>
      </div>
    </div>
  </article>
)

const Resources = ({ title, _rawDescription, items }) => (
  <section className="container padding">
    <header className="richtext">
      <h2 className="h2">{title}</h2>
      <SanityBlock body={_rawDescription} />
    </header>

    <div className={css.list}>
      {items.map((item, key) => (
        <Resource {...item} key={key} />
      ))}
    </div>
  </section>
)

export default Resources
