import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import LoadingLayout from 'layouts/Loading'
import HeroWithBg from 'components/common/HeroWithBg'
import Resources from 'components/pg-underwriting/Resources'
import Team from 'components/common/Team'

const App = () => {
  const { sanityUnderwritingPg: pg } = useStaticQuery(query)

  return (
    <DefaultLayout {...pg.seo}>
      <HeroWithBg {...pg.hero} />
      <Resources {...pg.resources} />
      <Team {...pg.team} />
    </DefaultLayout>
  )
}

const UnderwritingPg = withAuthenticationRequired(App, {
  onRedirecting: () => <LoadingLayout />
})

export default UnderwritingPg

const query = graphql`query UnderwritingPg {
  sanityUnderwritingPg {
    hero { ...hero }
    resources {
      title
      _rawDescription
      items {
        title
        description
        cta { ...cta }
        image { asset { gatsbyImageData(placeholder: NONE, aspectRatio: 2) } }
      }
    }
    team { ...employees }
    seo {
      title
      description
    }
  }
}`
